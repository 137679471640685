.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.m-auto {
  margin: auto;
}

.text-center {
  text-align: center;
}

.bg-white {
  background-color: white;
}

body {
  margin: 0;
}

html,
body,
#root,
.h-100 {
  height: 100%;
}
