.material__photo{
    min-height: 7rem;
    .name{
        margin: .6rem;
    }
    img{
        width: 16.4rem;
        height: 7.8rem;
    }    
}

#mui-component-select-photo{
    .MuiChip-root{
        height: auto;
    }
    img{
        width: 6.8rem;
        height: 4.4rem;
    }
}
