button.mt-2, form.mt-2 {
    margin-top: 16px;
}

.bg-suez-login {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-transparency-login {
    background-color: rgba(8, 8, 81, 0.74);
}
